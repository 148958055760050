import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useCurrentDoc } from 'docz'
import { useLocation } from '@reach/router'
import ogImageSrc from './ogImage.jpg'
import favicon from './favicon.ico'

type SeoProps = {
    description?: string
    title?: string
    menu?: string
}

const SEO: React.FC<SeoProps> = (props) => {
    const location = useLocation()
    const currentDoc = useCurrentDoc() || {}
    const menu = currentDoc.menu ?? props.menu
    const title = currentDoc.name || props.title || 'Entur Docs'

    const pageTitle = menu && menu !== title ? `${menu} | ${title}` : title
    const pageDescription =
        currentDoc.description ||
        props.description ||
        'Documentation for Entur APIs'
    const image = currentDoc.image || ogImageSrc
    return (
        <Helmet>
            <html lang="en" />
            <link rel="shortcut icon" type="image/png" href={favicon} />
            <title>{pageTitle} | Entur Docs</title>
            <meta name="description" content={pageDescription} />
            <meta property="og:title" content={pageTitle} />
            <meta property="og:type" content="website" />
            <meta
                property="og:url"
                content={`https://developer.entur.org${location.pathname}`}
            />
            <meta
                property="og:image"
                content={`https://developer.entur.org${image}`}
            />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:locale" content="en" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
            {props.children}
        </Helmet>
    )
}

export default SEO
