import React, { useState, useReducer } from 'react'
import qs from 'qs'
import { Button } from '@entur/button'
import { TextField } from '@entur/form'
import {
    Heading2,
    Heading3,
    Heading4,
    PreformattedText,
    Paragraph,
    CodeText,
} from '@entur/typography'

let ReactJson

/**
 * react-json-view does not support server-side rendering, and must therefore be dynamically
 * loaded to make sure it is only executed from the browser.
 *
 * https://github.com/mac-s-g/react-json-view/issues/121
 */
if (typeof window !== 'undefined') {
    import('react-json-view').then(({ default: ImportedModule }) => {
        ReactJson = ImportedModule
    })
}

function headersToObject(headers) {
    const headersObject = {}
    for (let header of headers.entries()) {
        const [key, value] = header
        headersObject[key] = value
    }
    return headersObject
}

function ResponseContent({ content, isJson }) {
    if (isJson && ReactJson) {
        return <ReactJson src={content} displayDataTypes={false} />
    }

    return (
        <PreformattedText>
            {JSON.stringify(content, undefined, 2)}
        </PreformattedText>
    )
}

function Response(resProps) {
    const { response } = resProps
    if (!response) return null

    const { status = '', statusText = '', headers, body } = resProps.response

    const contentType = Object.entries(headers).find(
        ([key]) => key.toLowerCase() === 'content-type',
    )?.[1]

    const bodyIsJson = contentType?.includes('json')

    return (
        <>
            <Heading3>Response</Heading3>
            <Paragraph
                style={Number(status) >= 400 ? { color: 'red' } : undefined}
            >{`${status} ${statusText}`}</Paragraph>
            <Heading4>Headers</Heading4>
            <ResponseContent content={headers} isJson />
            <Heading4>Body</Heading4>
            <ResponseContent content={body} isJson={bodyIsJson} />
        </>
    )
}

const initialState = {
    baseUrl: '',
    url: '',
    queryParameters: [],
    pathParameters: [],
}

function reducer(state, action) {
    switch (action.type) {
        case 'SET_QUERY_PARAMETER': {
            const newQueryParameters = {
                ...state.queryParameters,
                [action.key]: action.value,
            }
            return {
                ...state,
                queryParameters: newQueryParameters,
                url: state.baseUrl + '?' + qs.stringify(newQueryParameters),
            }
        }
        case 'SET_PATH_PARAMETER': {
            const newPathParameters = {
                ...state.pathParameters,
                [action.key]: action.value,
            }
            return {
                ...state,
                pathParameters: newPathParameters,
                url:
                    state.baseUrl.replace(`{${action.key}}`, action.value) +
                    '?' +
                    qs.stringify(state.queryParameters),
            }
        }
        default: {
            return state
        }
    }
}

function findPathParameters(url) {
    return (url.match(/\{(.+?)\}/g) || []).map((w) => w.replace(/[{}]/g, ''))
}

export default function RestTester(props) {
    const {
        url: baseUrl,
        summary,
        method = 'GET',
        queryParameters = [],
    } = props
    const [response, setResponse] = useState()
    const [loading, setLoading] = useState(false)

    const pathParameters = findPathParameters(baseUrl)

    const mappedQueryParameters = queryParameters.reduce(
        (obj, param) => ({
            ...obj,
            [param.name]: param.defaultValue,
        }),
        {},
    )

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        baseUrl,
        url: baseUrl + '?' + qs.stringify(mappedQueryParameters),
        queryParameters: mappedQueryParameters,
    })

    const { url } = state

    const doCall = async (event) => {
        try {
            event.preventDefault()
            setLoading(true)
            const res = await fetch(url, {
                method,
                headers: {
                    'ET-Client-Name': 'entur-devportal',
                },
            })
            const { status, statusCode, headers } = res

            let body
            const contentType = headers.get('Content-Type')
            if (contentType) {
                body = contentType.includes('json')
                    ? await res.json()
                    : await res.text()
            }

            setResponse({
                status,
                statusCode,
                headers: headersToObject(headers),
                body,
            })
        } catch (error) {
            console.error(error) // eslint-disable-line no-console
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <Heading2>{summary}</Heading2>
            <CodeText>
                {method.toUpperCase()} {url}
            </CodeText>
            <form onSubmit={doCall}>
                {pathParameters.length
                    ? pathParameters.map((name) => (
                          <TextField
                              key={name}
                              label={name}
                              value={state.pathParameters[name]}
                              type="text"
                              width="fluid"
                              onChange={(e) =>
                                  dispatch({
                                      type: 'SET_PATH_PARAMETER',
                                      key: name,
                                      value: e.currentTarget.value,
                                  })
                              }
                          />
                      ))
                    : null}
                {queryParameters.map(({ name, type }) => (
                    <TextField
                        key={name}
                        label={name}
                        value={state.queryParameters[name]}
                        type={!type || type === 'string' ? 'text' : type}
                        width="fluid"
                        onChange={(e) =>
                            dispatch({
                                type: 'SET_QUERY_PARAMETER',
                                key: name,
                                value: e.currentTarget.value,
                            })
                        }
                    />
                ))}
                <Button
                    variant="primary"
                    loading={loading}
                    style={{ marginTop: 16 }}
                >
                    Try it
                </Button>
            </form>
            <Response response={response} />
        </div>
    )
}
