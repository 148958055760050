import React from 'react'
import { SkipToContent } from '@entur/a11y'
import MenuButton from '../MenuButton/MenuButton'
import Menu from '~/components/Menu/Menu'
import Feedback from '~/components/Feedback/Feedback'
import { ToastProvider } from '@entur/alert'

import './Page.scss'

type TopBarProps = {
    toggleMenu: () => void
}

const TopBar: React.FC<TopBarProps> = ({ toggleMenu }) => {
    return (
        <div className="top-bar">
            <div className="top-bar__mobile-menu">
                <MenuButton onClick={toggleMenu} />
            </div>
        </div>
    )
}

type PageProps = {
    children: React.ReactNode
}

const Page: React.FC<PageProps> = (props) => {
    const { children } = props
    const [showMenu, setShowMenu] = React.useState(false)
    const [isMobile, setMobile] = React.useState(false) // default case which will be rendered server side
    React.useLayoutEffect(() => {
        const isMobile = window.innerWidth < 0
        setMobile(isMobile)
        setShowMenu(!isMobile)
    }, [])

    const toggleMenu = () => setShowMenu(!showMenu)
    return (
        <>
            <ToastProvider position="bottom-right">
                {showMenu && (
                    <SkipToContent mainId="site-content">
                        Go to main content
                    </SkipToContent>
                )}
                <div className="main">
                    {showMenu && (
                        <Menu
                            showCloseButton={isMobile}
                            onClose={() => setShowMenu(false)}
                        />
                    )}
                    <div style={{ width: '100%' }}>
                        <TopBar toggleMenu={toggleMenu} />
                        <div className="page">
                            <main id="site-content">{children}</main>
                        </div>
                    </div>
                    <Feedback />
                </div>
            </ToastProvider>
        </>
    )
}

export default Page
