import React from 'react'
import { theme, useCurrentDoc, ComponentsProvider } from 'docz'

import * as typography from '@entur/typography'

/* eslint-disable react/display-name */

import * as icons from '@entur/icons'

import { BannerAlertBox } from '@entur/alert'

import { ExpandablePanel } from '@entur/expand'

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    HeaderCell,
    DataCell,
} from '@entur/table'

import { CurrentSectionProvider } from '~/components/CurrentSectionProvider/CurrentSectionProvider'
import CardBoxGrid from '~/components/CardBoxGrid/CardBoxGrid'
import Changelog from '~/components/Changes/Changelog'
import GithubReleasesChangelog from '~/components/Changes/GithubReleasesChangelog'
import RestTester from '~/components/RestTester'
import Swagger from '~/components/Swagger'
import DownloadLink from '~/components/DownloadLink/DownloadLink'
import Page from '~/components/Page/Page'
import FrontPageNavBar from '~/components/FrontPageNavBar/FrontPageNavBar'
import FrontPageFooter from '~/components/FrontPageFooter/FrontPageFooter'
import NavBar from '~/components/NavBar/NavBar'

import './index.scss'

const map = {
    h1: typography.Heading1,
    h2: typography.Heading2,
    h3: typography.Heading3,
    h4: typography.Heading4,
    h5: typography.Heading5,
    h6: typography.Heading6,
    p: typography.Paragraph,
    a: typography.Link,
    pre: typography.PreformattedText,
    strong: typography.StrongText,
    b: typography.StrongText,
    inlineCode: typography.CodeText,
    ul: typography.UnorderedList,
    li: typography.ListItem,
    ol: typography.NumberedList,
    page: Page,
    CardBoxGrid,
    Changelog,
    GithubReleasesChangelog,
    RestTester,
    Swagger,
    DownloadLink,
    BannerAlertBox,
    ExpandablePanel,
    table: (props: HTMLTableElement) => <Table {...props} width="fluid" />,
    thead: TableHead,
    tbody: TableBody,
    tr: TableRow,
    th: (props: HTMLTableHeaderCellElement) => (
        <HeaderCell {...props} align="left" />
    ),
    td: DataCell,
    ...icons,
}

const App: React.FC = ({ children }: { children?: React.ReactNode }) => {
    const currentDoc = useCurrentDoc()
    return (
        <ComponentsProvider components={map}>
            {currentDoc.frontpage ? (
                <>
                    <FrontPageNavBar />
                    <main>{children}</main>
                    <FrontPageFooter />
                </>
            ) : (
                <CurrentSectionProvider>
                    <NavBar />
                    <Page>{children}</Page>
                </CurrentSectionProvider>
            )}
            <script src="https://lj5mck2ctgl0.statuspage.io/embed/script.js"></script>
        </ComponentsProvider>
    )
}

export default theme({})(App)
