import React from 'react'
import { MenuIcon } from '@entur/icons'

type MenuButtonProps = {
    onClick: () => void
}

const MenuButton: React.FC<MenuButtonProps> = ({ onClick }) => {
    return (
        <button className="page-button-menu" onClick={onClick}>
            <MenuIcon />
        </button>
    )
}

export default MenuButton
