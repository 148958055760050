import React, { ReactElement, ReactNode, useState } from 'react'

type CurrentSectionContextType = {
    currentSection: string
    setCurrentSection: (value: string) => void
}

const CurrentSectionContext = React.createContext<
    CurrentSectionContextType | undefined
>(undefined)

type CurrentSectionProviderProps = {
    children: ReactNode
}

export const CurrentSectionProvider = ({
    children,
}: CurrentSectionProviderProps): ReactElement => {
    const [currentSection, setCurrentSection] = useState('')
    return (
        <CurrentSectionContext.Provider
            value={{
                currentSection,
                setCurrentSection,
            }}
        >
            {children}
        </CurrentSectionContext.Provider>
    )
}

export const useCurrentSection = (): CurrentSectionContextType | undefined =>
    React.useContext(CurrentSectionContext)
