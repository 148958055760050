import React, { useEffect } from 'react'
import {
    useMenus,
    useDocs,
    useCurrentDoc,
    useConfig,
    MenuItem,
    Link,
} from 'docz'
import debounce from 'lodash.debounce'

import { SearchIcon, CloseIcon } from '@entur/icons'
import { IconButton } from '@entur/button'
import { TextField } from '@entur/form'
import { SideNavigation, SideNavigationItem } from '@entur/menu'
import { useCurrentSection } from '../CurrentSectionProvider/CurrentSectionProvider'
import EnturLogo from '../Logo/EnturLogo'
import './Menu.scss'

type SearchBarProps = {
    className: string
    searchText: string
    onSearchTextChange: (text: string) => void
}

const SearchBar: React.FC<SearchBarProps> = ({
    searchText,
    onSearchTextChange,
}) => {
    return (
        <>
            <TextField
                prepend={<SearchIcon inline />}
                label="Search..."
                value={searchText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onSearchTextChange(e.target.value)
                }
                width="fluid"
            />
        </>
    )
}

function filterMenus(allMenus: MenuItem[] | null, searchText: string) {
    const regex = new RegExp(searchText, 'i')
    return allMenus?.filter((menu) => {
        if (regex.test(menu.name) || regex.test(menu.tags)) return true
        if (!menu.menu) return false

        return menu.menu.some(
            ({ name, tags }) => regex.test(name) || regex.test(tags),
        )
    })
}

type SectionItem = {
    name: string
    menus: string[]
}

function groupMenusBySection(
    filteredMenus: MenuItem[],
    sections: SectionItem[],
) {
    const mappedSections = sections.map(({ name, menus }) => ({
        section: name,
        menus: menus
            .map((menuName) =>
                filteredMenus.find((menu) => menu.name === menuName),
            )
            .filter(Boolean),
    }))

    return mappedSections.filter(({ menus }) => menus.length > 0)
}

type SideNavigationBySectionProps = {
    filteredMenus: MenuItem[]
    sections: SectionItem[]
    searchText: string
}

function findCurrentSection(menusBySection: any, currentDocId: any): string {
    const currentSection = menusBySection.find((section) =>
        section.menus.some((menu) =>
            menu?.menu?.some((page) => page.id === currentDocId),
        ),
    )
    return currentSection?.section || ''
}

const SideNavigationBySection: React.FC<SideNavigationBySectionProps> = ({
    filteredMenus,
    sections,
    searchText,
}) => {
    const docs = useDocs()
    const currentDoc = useCurrentDoc()
    const currentDocId = currentDoc && currentDoc.id
    const { setCurrentSection } = useCurrentSection()
    let menusBySection = groupMenusBySection(filteredMenus, sections)
    const currentSection = findCurrentSection(menusBySection, currentDocId)

    useEffect(() => {
        setCurrentSection(currentSection)
    }, [currentSection, setCurrentSection])

    if (searchText == '') {
        menusBySection = menusBySection.filter(
            (section) => section.section == currentSection,
        )
    }

    return (
        <>
            {menusBySection.map(({ section, menus }) => (
                <SideNavigation size="small" key={section}>
                    {menus.map(({ id, name, menu }) => {
                        if (!menu || !docs) return null

                        const docsForMenu = docs.filter(
                            (doc) => doc.menu === name,
                        )

                        const indexDoc =
                            docsForMenu.find((doc) => doc.name === name) ||
                            docs.find((doc) => doc.route === menu[0].route)

                        if (!indexDoc) return null

                        const subMenuDocs = menu
                            .map((subMenuDoc) =>
                                docs?.find(
                                    (e) =>
                                        e.route === subMenuDoc.route &&
                                        e.name !== e.menu,
                                ),
                            )
                            .filter(Boolean)

                        return (
                            <SideNavigationItem
                                key={id}
                                as={Link}
                                to={indexDoc.route}
                                active={currentDocId === indexDoc.id}
                            >
                                {name}
                                {subMenuDocs.length ? (
                                    <SideNavigation size="small">
                                        {subMenuDocs.map((doc) => (
                                            <SideNavigationItem
                                                key={doc.id}
                                                active={currentDocId === doc.id}
                                                as={Link}
                                                to={doc.route}
                                            >
                                                {doc.name}
                                            </SideNavigationItem>
                                        ))}
                                    </SideNavigation>
                                ) : null}
                            </SideNavigationItem>
                        )
                    })}
                </SideNavigation>
            ))}
        </>
    )
}

export type MenuProps = {
    showCloseButton: boolean
    onClose: () => void
}

const Menu: React.FC<MenuProps> = (props) => {
    const { showCloseButton, onClose } = props
    const [searchText, setSearchText] = React.useState('')
    const { sections } = useConfig()
    const allMenus = useMenus()
    const menuRef = React.useRef(null)

    React.useEffect(() => {
        const menuRefCurrent = menuRef.current
        menuRefCurrent.scrollTo(
            0,
            Number(sessionStorage.getItem('menu-scroll')),
        )
        const scrollListener = menuRef.current.addEventListener(
            'scroll',
            debounce(
                (event) => {
                    if (!event.target) return
                    sessionStorage.setItem(
                        'menu-scroll',
                        event.target.scrollTop,
                    )
                },
                100,
                { leading: false, trailing: true },
            ),
        )
        return () =>
            menuRefCurrent.removeEventListener('scroll', scrollListener)
    }, [])
    const filteredMenus = filterMenus(allMenus, searchText)
    if (!filteredMenus) return null

    return (
        <div className="site-sidebar-wrapper">
            <div className="menu" ref={menuRef}>
                <div className="menu__top">
                    <div className="menu__top-bar">
                        {showCloseButton && onClose ? (
                            <IconButton onClick={onClose} aria-label="Close">
                                <CloseIcon width="24" height="24" />
                            </IconButton>
                        ) : null}
                    </div>
                    <div className="menu__logo">
                        <Link to="/">
                            <EnturLogo variant="blue" />
                        </Link>
                    </div>
                    <SearchBar
                        className="menu__searchbar"
                        searchText={searchText}
                        onSearchTextChange={setSearchText}
                    />
                </div>
                <SideNavigationBySection
                    filteredMenus={filteredMenus}
                    sections={sections}
                    searchText={searchText}
                />
            </div>
        </div>
    )
}

export default Menu
