import React from 'react'

import EnturBlue from './LogoBlue.svg'
import EnturWhite from './LogoWhite.svg'

function EnturLogo({ className, variant }: Props): JSX.Element | null {
    const enturLogo = variant === 'blue' ? EnturBlue : EnturWhite

    return (
        <img
            src={enturLogo}
            height={32}
            width={102}
            className={className}
            alt="Entur logo"
            aria-label="Entur Logo"
        />
    )
}

interface Props {
    className?: string
    height?: number
    variant?: 'blue' | 'white'
}

export default EnturLogo
