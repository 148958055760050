import React from 'react'
import { Contrast } from '@entur/layout'
import EnturLogo from '../Logo/EnturLogo'
import { TopNavigationItem } from '@entur/menu'
import { Link } from 'docz'
import './FrontPageNavBar.scss'

export default function FrontPageNavBar(): JSX.Element {
    return (
        <Contrast className="frontpage-nav-bar">
            <div className="frontpage-nav-bar__logo">
                <Link to="/">
                    <EnturLogo variant="white" />
                </Link>
            </div>
            <nav className="frontpage-nav-bar__links">
                <TopNavigationItem
                    className="frontpage-nav-bar__links__link"
                    as={Link}
                    to="/pages-intro-overview"
                >
                    Overview
                </TopNavigationItem>
                <TopNavigationItem
                    className="frontpage-nav-bar__links__link"
                    as={Link}
                    to="/stops-and-timetable-data"
                >
                    Open Data
                </TopNavigationItem>
                <TopNavigationItem
                    className="frontpage-nav-bar__links__link"
                    as={Link}
                    to="/pages-geocoder-intro"
                >
                    Open Services
                </TopNavigationItem>
                <TopNavigationItem
                    className="frontpage-nav-bar__links__link"
                    as={Link}
                    to="/account-based-ticketing-getting-started"
                >
                    Partner Services
                </TopNavigationItem>
                <TopNavigationItem
                    className="frontpage-nav-bar__links__link"
                    as={Link}
                    to="/pages-electronic-journal-intro"
                >
                    Internal
                </TopNavigationItem>
            </nav>
        </Contrast>
    )
}
