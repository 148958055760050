import React from 'react'

import './CardBoxGrid.scss'

type CardBoxGridProps = {
    children: React.ReactNode
    className?: string
    [key: string]: any
}

const CardBoxGrid: React.FC<CardBoxGridProps> = ({
    children,
    className,
    ...rest
}) => {
    return (
        <div className={`card-box-grid ${className}`} {...rest}>
            {children}
        </div>
    )
}

export default CardBoxGrid
