import React from 'react'

import { TextArea } from '@entur/form'
import { Button, FloatingButton } from '@entur/button'
import { Modal } from '@entur/modal'
import { Heading4, Paragraph, Link } from '@entur/typography'
import { CommentIcon, ChatIcon } from '@entur/icons'
import { BannerAlertBox } from '@entur/alert'

import IconCircle from '../IconCircle/IconCircle'

import './Feedback.scss'

type FeedbackModalTriggerProps = {
    isFooterFeedbackLink?: boolean
    setShowModal: (showModal: boolean) => void
}

const FeedbackModalTrigger: React.FC<FeedbackModalTriggerProps> = ({
    isFooterFeedbackLink,
    setShowModal,
}) => {
    if (isFooterFeedbackLink) {
        return (
            <Link
                href=""
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.preventDefault()
                    setShowModal(true)
                }}
            >
                Give feedback
            </Link>
        )
    }

    return (
        <FloatingButton
            aria-label="Open Feedback Dialog"
            className="feedback__floating-button feedback--show-modal"
            onClick={() => setShowModal(true)}
            style={{ position: 'fixed', bottom: '1em', right: '1em' }}
        >
            Feedback
            <CommentIcon color="white" />
        </FloatingButton>
    )
}

type FeedbackProps = {
    isFooterFeedbackLink?: boolean
}

const Feedback: React.FC<FeedbackProps> = ({ isFooterFeedbackLink }) => {
    const [showModal, setShowModal] = React.useState(false)
    const [feedbackText, setFeedbackText] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [isError, setIsError] = React.useState(false)
    const [locationHref, setLocationHref] = React.useState('')

    React.useEffect(() => {
        setLocationHref(window.location.href)
    }, [])

    const submitFeedback = async (event: React.FormEvent) => {
        event.preventDefault()
        setLoading(true)
        try {
            await fetch(
                'https://hooks.slack.com/services/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',
                {
                    method: 'POST',
                    body: JSON.stringify({
                        attachments: [
                            {
                                title: 'Feedback',
                                pretext: locationHref,
                                text: feedbackText,
                                mrkdwn_in: ['text'],
                            },
                        ],
                    }),
                },
            )
            setLoading(false)
            setShowModal(false)
            setFeedbackText('')
            setIsError(false)
        } catch (error) {
            setIsError(true)
            setLoading(false)
        }
    }

    return (
        <>
            <FeedbackModalTrigger
                isFooterFeedbackLink={isFooterFeedbackLink}
                setShowModal={setShowModal}
            />
            <Modal
                closeLabel="Close"
                open={showModal}
                title="Questions and Feedback"
                size="medium"
                onDismiss={() => {
                    setShowModal(false)
                    setIsError(false)
                }}
            >
                <div className="feedback-grid">
                    <div className="feedback-section__icon">
                        <IconCircle icon={<ChatIcon />} />
                    </div>
                    <div className="feedback-section__content">
                        <Heading4
                            className="feedback-section__heading"
                            margin="none"
                        >
                            Questions?
                        </Heading4>
                        <Paragraph>
                            Do you have questions about our APIs?
                            <br />
                            Send an email to{' '}
                            <Link href="mailto:kollektivdata@entur.org">
                                kollektivdata@entur.org
                            </Link>
                            .
                        </Paragraph>
                    </div>
                    <div className="feedback-section__spacer" />
                    <div className="feedback-section__icon">
                        <IconCircle icon={<CommentIcon />} />
                    </div>
                    <div className="feedback-section__content">
                        <Heading4
                            className="feedback-section__heading"
                            margin="none"
                        >
                            Feedback
                        </Heading4>
                        <Paragraph>
                            We appreciate any feedback about this article or
                            about the site in general
                        </Paragraph>
                        <form onSubmit={submitFeedback}>
                            <TextArea
                                label="Please write your feedback here"
                                id="feedback-input"
                                className="feedback-input"
                                value={feedbackText}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                    setFeedbackText(e.currentTarget.value)
                                }}
                            />
                            {isError && (
                                <BannerAlertBox
                                    className="feedback-error"
                                    title="The message could not be sent"
                                    size="large"
                                    variant="error"
                                >
                                    Please try again later or send an email to{' '}
                                    <Link href="mailto:kollektivdata@entur.org">
                                        kollektivdata@entur.org
                                    </Link>
                                </BannerAlertBox>
                            )}
                            <Button
                                className="feedback-modal__submit-button"
                                type="submit"
                                variant="primary"
                                width="fluid"
                                loading={loading}
                                disabled={loading || !feedbackText}
                            >
                                Send feedback
                            </Button>
                        </form>
                    </div>
                    <div className="feedback-section__spacer" />
                </div>
            </Modal>
        </>
    )
}

export default Feedback
