import React from 'react'

import { getChangelogFromGithubReleases } from '~/utils/releases'
import Changelog, { ChangelogProps } from './Changelog'

type Props = {
    /**
     * The name of the user or organization that owns the repo, i.e. "entur"
     */
    owner: string
    /**
     * The name of the repository, i.e. "sdk"
     */
    repo: string
    /**
     * The human-friendly name of the thing the repo represents, i.e. "SDK"
     */
    repoName?: string
    team?: string
    changelogPage?: string
}

const GithubReleasesChangelog: React.FC<Props> = (props) => {
    const [changelogData, setChangelogData] = React.useState<
        ChangelogProps['changelogData']
    >({ changes: [] })

    React.useEffect(() => {
        getChangelogFromGithubReleases(props)
            .then(setChangelogData)
            .catch((error) => console.error(error.message))
    }, [props])

    return <Changelog changelogData={changelogData} />
}

export default GithubReleasesChangelog
