import React, { useState, useEffect } from 'react'
import RefParser from '@apidevtools/json-schema-ref-parser'

import { BannerAlertBox } from '@entur/alert'
import { Button } from '@entur/button'
import { ExpandablePanel } from '@entur/expand'
import { Loader } from '@entur/loader'

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    HeaderCell,
    DataCell,
} from '@entur/table'

import RestTester from '../RestTester'

import './styles.scss'
import { Heading3, Paragraph } from '@entur/typography'

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
function getCircularReplacer() {
    const seen = new WeakSet()
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return
            }
            seen.add(value)
        }
        return value
    }
}

async function fetchSwaggerDoc(url) {
    const res = await fetch(url)
    const doc = await res.json()
    await RefParser.dereference(doc)
    return doc
}

function getBaseUrl(swaggerDoc) {
    if (swaggerDoc.swagger === '2.0') {
        return `https://${swaggerDoc.host || ''}${swaggerDoc.basePath || ''}`
    }
    if (swaggerDoc.openapi) {
        return swaggerDoc.servers[0].url
    }
}

function flattenSwaggerPathStructure(paths) {
    return Object.keys(paths).reduce(
        (acc, path) => [
            ...acc,
            ...Object.keys(paths[path]).map((method) => ({
                ...paths[path][method],
                path,
                method,
            })),
        ],
        [],
    )
}

function groupEndpointsByTags(endpoints, tags) {
    return tags.map(({ name, description }) => ({
        tagName: name,
        tagDescription: description,
        endpoints: endpoints.filter(
            (endpoint) =>
                endpoint.tags && endpoint.tags.some((tag) => name === tag),
        ),
    }))
}

function getTags(swaggerDoc, endpoints) {
    const allTags = [...(swaggerDoc.tags ?? [])]

    // Add tags that are used in paths, but not declared explicitly
    endpoints.forEach((endpoint) => {
        endpoint.tags?.forEach((tag) => {
            if (!allTags.some((t) => t.name === tag)) {
                allTags.push({ name: tag, description: '' })
            }
        })
    })

    return allTags.length > 0 ? allTags : null
}

const petstoreEndpointUrl = (petstoreUrl, endpoint) =>
    `${petstoreUrl}#/${endpoint.tags[0] ?? 'default'}/${endpoint.operationId}`

function ParameterTable({ title, parameters }) {
    if (!parameters || !parameters.length) return null

    return (
        <>
            <h3>{title}</h3>
            <Table>
                <TableHead>
                    <TableRow>
                        <HeaderCell align="left">Param Name</HeaderCell>
                        <HeaderCell align="left">Required</HeaderCell>
                        <HeaderCell align="left">Type</HeaderCell>
                        <HeaderCell align="left">Description</HeaderCell>
                        <HeaderCell align="left">Default</HeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {parameters.map((param) => (
                        <TableRow key={param.name}>
                            <DataCell>{param.name}</DataCell>
                            <DataCell>{param.required ? 'Yes' : 'No'}</DataCell>
                            <DataCell>
                                {param.type ?? param.schema.type ?? ''}
                            </DataCell>
                            <DataCell>
                                {param.description ??
                                    param.schema.description ??
                                    ''}
                            </DataCell>
                            <DataCell>
                                {param.default ?? param.schema.default ?? ''}
                            </DataCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

function JsonSchema({ schema, title }) {
    return (
        <ExpandablePanel title={title}>
            {schema.description}
            <div>
                <a href="https://json-schema.org/">JSON Schema:</a>
            </div>
            <pre>
                <code>{JSON.stringify(schema, getCircularReplacer(), 2)}</code>
            </pre>
        </ExpandablePanel>
    )
}

function Endpoint({ endpoint, baseUrl, deprecated, petstoreUrl }) {
    const {
        path,
        method,
        description,
        parameters = [],
        responses,
        summary,
    } = endpoint

    const headerParameters = parameters.filter(
        (parameter) => parameter.in === 'header',
    )
    const pathParameters = parameters.filter(
        (parameter) => parameter.in === 'path',
    )
    const queryParameters = parameters.filter(
        (parameter) => parameter.in === 'query',
    )
    const bodyParameters = parameters.filter(
        (parameter) => parameter.in === 'body',
    )

    // In OpenAPI v3 body is not in "parameters" anymore
    const requestBodyJson =
        endpoint.requestBody?.content?.['application/json']?.schema

    const requiresAuth = headerParameters.some(
        ({ name, required }) =>
            name.toLowerCase() === 'authorization' && required,
    )

    const title =
        summary?.length > 0
            ? `${method.toUpperCase()} ${path} - ${summary}`
            : `${method.toUpperCase()} ${path} `

    return (
        <ExpandablePanel
            title={title}
            size="lg"
            className={`endpoint ${deprecated ? 'endpoint--deprecated' : ''}`}
        >
            <div key={path + method}>
                <h2>
                    {method.toUpperCase()} {path}
                </h2>
                {deprecated ? (
                    <span className="endpoint__warning">
                        Warning: Deprecated
                    </span>
                ) : null}
                <p>{description}</p>
                {petstoreUrl && (
                    <p>
                        <a href={petstoreEndpointUrl(petstoreUrl, endpoint)}>
                            Show on Swagger Petstore
                        </a>
                    </p>
                )}
                {method === 'get' && !requiresAuth ? (
                    <ExpandablePanel title="Try it!">
                        <RestTester
                            url={baseUrl + path}
                            queryParameters={queryParameters.map(
                                ({ name, type, default: defaultValue }) => ({
                                    name,
                                    type,
                                    defaultValue,
                                }),
                            )}
                        />
                    </ExpandablePanel>
                ) : null}
                <ParameterTable title="Headers" parameters={headerParameters} />
                <ParameterTable title="Path" parameters={pathParameters} />
                <ParameterTable title="Query" parameters={queryParameters} />
                <ParameterTable title="Body" parameters={bodyParameters} />
                {requestBodyJson && (
                    <>
                        <h3>Body</h3>
                        <JsonSchema title="JSON" schema={requestBodyJson} />
                    </>
                )}
                <h3>Responses</h3>
                {responses
                    ? Object.keys(responses).map((status) => {
                          const { description, schema, content } =
                              responses[status]
                          const title = `${status} ${description}`

                          const jsonSchema =
                              schema ?? content?.['application/json']?.schema

                          if (jsonSchema) {
                              return (
                                  <JsonSchema
                                      schema={jsonSchema}
                                      title={title}
                                      key={status + path}
                                  />
                              )
                          }

                          return (
                              <h4
                                  key={path + status}
                                  title={`${status} ${description}`}
                              >
                                  {title}
                              </h4>
                          )
                      })
                    : null}
            </div>
        </ExpandablePanel>
    )
}

function renderEndpoints(endpoints, baseUrl, petstoreUrl) {
    return endpoints.map((endpoint) => (
        <Endpoint
            key={endpoint.path + endpoint.method}
            endpoint={endpoint}
            baseUrl={baseUrl}
            petstoreUrl={petstoreUrl}
            deprecated={endpoint.deprecated}
        />
    ))
}

export default function Swagger({
    url,
    fallback,
    baseUrl,
    shouldGroupEndpointsByTags = true,
    showBaseUrl = true,
    operationIds = null,
}) {
    const [swaggerDoc, setSwaggerDoc] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()

    useEffect(() => {
        setLoading(true)
        fetchSwaggerDoc(url)
            .then(setSwaggerDoc)
            .catch((error) => {
                console.error(error)
                setError(error)
            })
            .then(() => setLoading(false))
    }, [url])

    if (loading) {
        return <Loader>Loading documentation...</Loader>
    }

    const petstoreUrl = `https://petstore.swagger.io/?url=${url}`

    if (error) {
        const fallbackUrl = fallback || petstoreUrl
        return (
            <BannerAlertBox title="Error" size="large" variant="error">
                Could not load the documentation. Try viewing it here:{' '}
                <a href={fallbackUrl}>{fallbackUrl}</a>
            </BannerAlertBox>
        )
    }

    if (!swaggerDoc || !swaggerDoc.paths) {
        return null
    }

    const theBaseUrl = baseUrl || getBaseUrl(swaggerDoc)
    const endpoints = flattenSwaggerPathStructure(swaggerDoc.paths).filter(
        (p) => operationIds == null || operationIds.includes(p.operationId),
    )

    const tags = getTags(swaggerDoc, endpoints)

    if (!tags || !shouldGroupEndpointsByTags) {
        return (
            <Paragraph>
                {showBaseUrl && <span>Base URL: {theBaseUrl}</span>}
                {renderEndpoints(endpoints, theBaseUrl, petstoreUrl)}
            </Paragraph>
        )
    }

    const endpointsByTag = groupEndpointsByTags(endpoints, tags)

    return (
        <Paragraph>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                }}
            >
                {showBaseUrl && (
                    <>
                        <span>Base URL: {theBaseUrl}</span>
                        <Button
                            variant="secondary"
                            as="a"
                            href={url}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            Raw Swagger
                        </Button>
                    </>
                )}
            </div>
            {endpointsByTag.map(({ tagName, tagDescription, endpoints }) => (
                <div key={tagName}>
                    <Heading3>{tagName}</Heading3>
                    {tagDescription && <Paragraph>{tagDescription}</Paragraph>}
                    {renderEndpoints(endpoints, theBaseUrl, petstoreUrl)}
                </div>
            ))}
        </Paragraph>
    )
}
